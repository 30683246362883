<template>
  <div class="fs-container">
    <div
      v-if="bannerPromotion"
      class="fs-section section__voucher voucher__detail fs-pc"
    >
      <div class="fs-inr voucher__inr">
        <div class="voucher__box">
          <div class="fs-pic voucher__pic">
            <img
              loading="lazy"
              :src="bannerPromotion.Thumbnail"
              alt="Banner Desktop"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="fs-section promotion__detail">
      <div class="fs-inr promotion__detail__inr">
        <div v-if="currentVoucherDetail">
          <div class="promotion__detail__box">
            <div class="promotion__item">
              <div
                v-if="currentVoucherDetail.images_rectangle"
                class="fs-pic promotion__pic"
              >
                <img
                  :src="currentVoucherDetail.images_rectangle[640]"
                  alt="Waterproof Sport Smart Watch Monitor for iOS - Android"
                />
              </div>
              <div class="core__block">
                <div
                  class="fs-bg cmBg core__bg"
                  :style="{
                    backgroundImage: `url(${currentVoucherDetail.brandImage})`,
                  }"
                ></div>
                <!-- <div class="fs-buts fs-sp">
                <button class="fs-but" (click)="showPopup(popupEnum.ExchangePoints)">Nhận ưu đãi</button>
                </div> -->
                <div v-if="!isLogin" class="fs-buts fs-sp">
                  <button class="fs-but" @click="showPopup(popupEnum.Login)">
                    Đăng nhập để nhận ưu đãi
                  </button>
                </div>
                <div v-if="isLogin" class="fs-buts fs-sp">
                  <button
                    class="fs-but"
                    @click="showPopup(popupEnum.ExchangePoints)"
                  >
                    Nhận ưu đãi
                  </button>
                </div>
                <div class="core__txt">
                  <h3>{{ currentVoucherDetail.title }}</h3>
                  <div class="core__value">
                    {{ currentVoucherDetail.point }} điểm
                  </div>
                  <div class="core__date">
                    {{ currentVoucherDetail.expire_duration }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Mobile  tab -->
            <div class="fs-sp tab_header_box">
              <b-row cols="2">
                <b-col
                  :class="`tab_item ${activeTabPopup == 1 ? 'active' : ''}`"
                  @click="() => onActiveTabPopup(1)"
                >
                  <p class="h4 text-center">ĐIỀU KIỆN ÁP DỤNG</p>
                </b-col>
                <b-col
                  @click="() => onActiveTabPopup(2)"
                  :class="`tab_item ${activeTabPopup == 2 ? 'active' : ''}`"
                >
                  <p class="h4 text-center">THÔNG TIN CHUNG</p>
                </b-col>
              </b-row>
            </div>

            <div
              :class="`tab__box tab__condition ${
                activeTabPopup == 1 ? 'active' : ''
              }`"
            >
              <h4>ĐIỀU KIỆN ÁP DỤNG</h4>
              <div
                class="condition__list"
                v-html="currentVoucherDetail.note"
              ></div>

              <div v-if="!isLogin">
                <div class="fs-buts fs-pc">
                  <button class="fs-but" @click="showPopup(popupEnum.Login)">
                    Đăng nhập để nhận ưu đãi
                  </button>
                </div>
              </div>
              <div v-if="isLogin">
                <div class="fs-buts fs-pc">
                  <button
                    class="fs-but"
                    @click="showPopup(popupEnum.ExchangePoints)"
                  >
                    Nhận ưu đãi
                  </button>
                </div>
              </div>
            </div>

            <div
              :class="`tab__box tab__info ${
                activeTabPopup == 2 ? 'active' : ''
              }`"
            >
              <h4>THÔNG TIN CHUNG</h4>
              <div v-html="currentVoucherDetail.content"></div>
            </div>
          </div>
        </div>

        <div
          v-if="normalVoucher && normalVoucher.length > 0"
          class="promotion__top"
        >
          <div class="promotion__title">
            <h2>Ưu đãi tương tự</h2>
          </div>
          <!-- PC Paging -->
          <div class="col-6">
            <div class="text-right fs-pc">
              <b-button
                variant="flat-primary"
                class="btn-icon"
                :disabled="activeIndexSide == 0"
                @click="onPrevVocuher"
              >
                <feather-icon size="35" icon="ArrowLeftCircleIcon" />
              </b-button>

              <b-button
                variant="flat-primary"
                class="btn-icon"
                :disabled="isEndSlide"
                @click="onNextVoucher"
              >
                <feather-icon size="35" icon="ArrowRightCircleIcon" />
              </b-button>
            </div>
          </div>
        </div>
        <!-- Mobile Paging -->
        <div class="fs-sp">
          <div class="row justify-content-between align-items-center">
            <b-button
              variant="flat-primary"
              class="btn-icon"
              :disabled="activeIndexSide == 0"
              @click="onPrevVocuher"
            >
              <feather-icon size="25" icon="ArrowLeftIcon" />
            </b-button>

            <div v-if="snapGrid.length > 0" class="row">
              <div v-for="(snap, index) in snapGrid" :key="index" class="ml-1">
                <div
                  :class="`${currentSnap == index ? 'snap-active' : 'snap'}`"
                  @click="onSnap(index)"
                ></div>
              </div>
            </div>
            <b-button
              variant="flat-primary"
              class="btn-icon"
              :disabled="isEndSlide"
              @click="onNextVoucher"
            >
              <feather-icon size="25" icon="ArrowRightIcon" />
            </b-button>
          </div>
        </div>

        <div class="promotion__box">
          <div v-if="normalVoucher" class="promotion__slider">
            <swiper
              :slides-per-view="rowSlide"
              :space-between="20"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
            >
              <swiper-slide
                v-for="(promotion, index) in normalVoucher"
                :key="promotion.id"
                :virtualIndex="index"
              >
                <div class="swiper-wrapper">
                  <a
                    :href="`/club/voucher-detail/${promotion.voucher_id}`"
                    class="promotion__item"
                  >
                    <div class="promotion__money">
                      {{ formatPrice(promotion.price) }} đ
                    </div>
                    <div
                      class="fs-bg cmBg promotion__bg"
                      :style="`background-image:url(${promotion.image})`"
                    ></div>
                    <div class="fs-txt promotion__txt">
                      <h3>{{ promotion.title }}</h3>
                    </div>
                    <div class="core__block">
                      <div
                        class="fs-bg cmBg core__bg"
                        :style="`background-image:url(${promotion.brandImage})`"
                      ></div>
                      <div class="core__txt">
                        <div class="core__value">
                          {{ promotion.point }} điểm
                        </div>
                        <div class="core__date">
                          {{ promotion.expire_duration }}
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </swiper-slide>
            </swiper>
          </div>
          <!-- <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
          <div class="swiper-pagination"></div> -->
          <div
            v-if="snapGrid.length > 0"
            class="row justify-content-center mt-5"
          >
            <div
              v-for="(snap, index) in snapGrid"
              :key="index"
              class="ml-1 fs-pc"
            >
              <div
                :class="`${currentSnap == index ? 'snap-active' : 'snap'}`"
                @click="onSnap(index)"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="'popup-overlay ' + modalClass">
      <!-- popup đổi điểm -->
      <div
        class="popup-inr message-box exchange__points has__close__circle"
        v-if="currentPopUp == popupEnum.ExchangePoints"
      >
        <div class="close-popup" @click="closePopup()"></div>
        <div class="fs-pic icon__check">
          <img src="../assets/images/icon-check-red.png" alt="Đổi điểm" />
        </div>
        <h2>Xác nhận đổi điểm</h2>
        <div class="fs-box">
          <div class="change__point__box">
            <span class="txt__point"
              >{{ formatPrice(currentVoucherDetail.point) }} điểm</span
            >
            <span class="pic__change"
              ><img src="../assets/images/icon-change.png" alt=""
            /></span>
            <span class="txt__point"
              >{{ formatPrice(currentVoucherDetail.price) }} đ</span
            >
          </div>
          <div class="fs-txt">
            <p>Điểm sẽ được trừ vào tổng điểm tích lũy <br />của iTel Club</p>
          </div>
          <div class="fs-group fs-buts">
            <button
              type="button"
              class="fs-but"
              title="Quay lại"
              @click="closePopup()"
            >
              <span>Quay lại</span>
            </button>
            <div class="ml-2" />
            <button
              type="button"
              class="fs-but next-mask-red"
              title="Xác nhận"
              @click="exchangedVoucher(currentVoucherDetail.voucher_id)"
            >
              <span>Xác nhận</span>
            </button>
          </div>
        </div>
      </div>

      <!-- popup nhận ưu đãi thành công -->
      <div
        class="popup-inr message-box endow__success has__close__circle"
        v-if="
          currentPopUp == popupEnum.QRCodeDetail &&
          exchangeVoucherResponse != null
        "
      >
        <div class="close-popup" @click="closePopup()"></div>
        <h2>Nhận ưu đãi thành công</h2>
        <div class="fs-box">
          <div class="fs-pic logo__ur">
            <img src="../assets/images/logo-ur.png" alt="urbox" />
          </div>
          <div class="box__qr">
            <div class="fs-pic">
              <img :src="exchangeVoucherResponse.codeImage" alt="QR code" />
            </div>
            <div class="fs-txt">
              <p>{{ exchangeVoucherResponse.code }}</p>
            </div>
          </div>
          <div class="qr__brief">
            <p>
              Quý khách vui lòng mở trực tiếp ưu đãi trên <br />web/app/tin nhắn
              cho nhân viên khi thanh toán <br />(không đưa ảnh chụp màn hình)
            </p>
            <p>
              <strong
                >Hạn sử dụng: {{ exchangeVoucherResponse.expiredDate }}
              </strong>
            </p>
            <p><strong>Hotline: 1900 299 232</strong></p>
          </div>
          <div class="col-md-12">
            <SurveyCES :orderId="exchangeVoucherResponse.transactionId" :phone="loginLoyalty.phone"/>
          </div>
        </div>
      </div>
      <!-- popup otp fail 4-->

      <div
        class="popup-inr message-box otp__fail has__close__circle"
        v-if="currentPopUp == popupEnum.ExchangeFail604"
      >
        <div class="close-popup" @click="closePopup()"></div>
        <h2>Nhận ưu đãi không thành công</h2>
        <div class="outer-wrap">
          <p>Nhận ưu đãi không thành công<br>Tiếc quá!!! Ưu đãi không dành cho hạng hội viên của bạn 😭<br>Mời bạn tham khảo ưu đãi khác nha ❤️</p>
        </div>
      </div>
      <!--  -->
      <div
        class="popup-inr message-box otp__fail has__close__circle"
        v-if="currentPopUp == popupEnum.ExchangeFail601"
      >
        <div class="close-popup" @click="closePopup()"></div>
        <h2>Nhận ưu đãi không thành công</h2>
        <div class="outer-wrap">
          <p>Nhận ưu đãi không thành công<br>Buồn quá hic hic!!! Bạn không đủ điểm để nhận ưu đãi 😭<br />Mời bạn tham khảo ưu đãi khác nha ❤️</p>
        </div>
      </div>

      <!-- Popup khách hàng chưa vào itel Club -->
      <div
        class="popup-inr message-box otp__fail has__close__circle"
        v-if="currentPopUp == popupEnum.PendingUser"
      >
        <div class="close-popup" @click="closePopup()"></div>
        <h2>Nhận ưu đãi không thành công</h2>
        <div class="outer-wrap">
          <p><b>Tiếc quáaa!!!</b><br>
            Số điện thoại của bạn chưa đủ điều kiện để trở thành hội viên iTel Club 😭<br>
            Sau 3 ngày kể từ ngày kích hoạt, số điện thoại của bạn sẽ đươc tham gia chương trình Hội viên thân thiết iTel Club.<br>
            Chi tiết LH 087708787 (0đ cho TB iTel)<br>
            Trân trọng!
          </p>
        </div>
      </div>

      <!-- popup otp fail 2-->
      <div
        class="popup-inr message-box otp__fail has__close__circle"
        v-if="currentPopUp == popupEnum.ExchangeFail602"
      >
        <div class="close-popup" @click="closePopup()"></div>
        <h2>Nhận ưu đãi không thành công</h2>
        <div class="outer-wrap">
          <p>Nhận ưu đãi không thành công<br>Teng teng!!! Bạn đã sử dụng quá số lần nhận ưu đãi VIP trong tháng 😭<br />Mời bạn tham khảo ưu đãi khác nha ❤️</p>
        </div>
      </div>

      <!-- popup otp fail 5-->
      <div
        class="popup-inr message-box otp__fail has__close__circle"
        v-if="currentPopUp == popupEnum.ExchangeFail603"
      >
        <div class="close-popup" @click="closePopup()"></div>
        <h2>Nhận ưu đãi không thành công</h2>
        <div class="outer-wrap">
          <p>
            Số lượng voucher không đủ, vui lòng chat trực tiếp <br />với iTel
            hoặc liên hệ hotline 0877 087 087 <br />(miễn phí cước thuê bao
            iTel)
          </p>
        </div>
      </div>

      <!-- popup nhận ưu đãi không thành công -->
      <!-- <div
        class="popup-inr message-box endow__fail has__close__circle"
        v-if="currentPopUp == popupEnum.ExchangeFail604"
      >
        <div class="close-popup" @click="closePopup()"></div>
        <div class="fs-pic icon__fail">
          <img
            src="../assets/images/icon-close-white.png"
            alt="Nhận ưu đãi không thành công"
          />
        </div>
        <h2>Nhận ưu đãi không thành công</h2>
        <p>Ưu đãi không dành cho hạng hội viên của bạn</p>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Navigation } from "swiper";
import { SwiperCore, Swiper, SwiperSlide, Controller } from "swiper-vue2";
import useJwt from "@/auth/jwt/useJwt";
import moment from "moment";
import SurveyCES from "@/components/SurveyCES.vue";
import {
  BCard,
  BRow,
  BCol,
  BContainer,
  BPagination,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BModal,
  BButton,
} from "bootstrap-vue";
import { isMobile } from "mobile-device-detect";
// Import Swiper styles
import "swiper/swiper-bundle.css";
import "../assets/css/popup.css";
import "../assets/css/layout.css";

SwiperCore.use([Navigation]);

const popupEnum = {
  Login: 1,
  Otp: 2,
  InputWrongPhoneOrOtp: 3,
  InputWrongMoreThan3Time: 4,
  NotSentOtp: 5,
  PromotionDetail: 6,
  QRCodeDetail: 7,
  ExchangePoints: 8,
  ExchangeFail601: 9,
  ExchangeFail602: 10,
  ExchangeFail603: 11,
  ExchangeFail604: 12,
  PendingUser: 13,
};

export default {
  name: "VoucherDetail",
  data() {
    return {
      isLogin: false,
      currentVoucherDetail: {},
      normalVoucher: null,
      tokenLoyalty: "",
      bannerPromotion: null,
      currentPopUp: null,
      modalClass: "",
      loginLoyalty: {
        Phone: "",
        Otp: "",
      },
      serverParams: {
        currentPage: 1,
        pageSize: 6,
        totalRows: 0,
        categoryId: null,
        cityId: null,
        pointId: null,
        rankId: 0,
        voucherId: 0,
      },
      vipVoucherParams: {
        currentPage: 1,
        pageSize: 20,
        totalRows: 0,
        totalPages: 1,
      },
      popupEnum: popupEnum,
      exchangeVoucherResponse: null,
      rowSlide: isMobile ? 2 : 3,
      isMobile,
      activeTabPopup: 1,
      swiper: null,
      isEndSlide: false,
      activeIndexSide: 0,
      currentSnap: 0,
      snapGrid: [],
    };
  },

  methods: {
    async getBannerLoyalty() {
      try {
        const res = await useJwt.post("club/get-banner-loyalty", {
          columnFilters: {},
          sort: [],
          page: 1,
          pageSize: 1000,
          lang: 1,
        });
        if (res.data.code == 200) {
          this.bannerPromotion = res.data.result.BannerPromotion;
        }
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },
    async getVoucherDetail() {
      try {
        const res = await useJwt.get(
          `club/vouchers/${this.$route.params.Slug}`,
          {
            headers: {
              apiKey: `${this.tokenLoyalty}`,
            },
          }
        );

        if (res.data.code == 200) {
          this.currentVoucherDetail = res.data.result.data;
          // parent_cat_id
          if (res.data.result.data.voucher_type == "VIP") {
            return this.getVipVoucherUser();
          }
          this.serverParams.categoryId = res.data.result.data.parent_cat_id;
          this.getFillteredVouchers(res.data.result.data.parent_cat_id);
        }
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },

    async getFillteredVouchers(categoryId) {
      this.loading(true);
      try {
        const res = await useJwt.post(
          `club/filtered-voucher?page=${this.serverParams.currentPage}&pageSize=${this.serverParams.pageSize}`,
          {
            categoryId: categoryId,
            cityId: this.serverParams.cityId,
            pointId: this.serverParams.pointId,
            rankId: this.serverParams.rankId,
            voucherId: this.$route.params.Slug,
          }
        );
        if (res.data.code == 200) {
          this.loading(false);
          this.normalVoucher = res.data.result.data.content;
        }
      } catch (error) {
        this.loading(false);

        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },

    async exchangedVoucher() {
      try {
        const res = await useJwt.post(
          `club/vouchers/client`,
          {
            voucherId: this.$route.params.Slug,
          },
          {
            headers: {
              apiKey: `${this.tokenLoyalty}`,
            },
          }
        );

        if (res.data.code == 200) {
          this.exchangeVoucherResponse = res.data.result.data;
          this.currentPopUp = popupEnum.QRCodeDetail;
        } else {
          console.log("ress->", res);
          this.$toast.error(
            "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
            { icon: true, closeButton: "button" }
          );
        }
      } catch (error) {
        if (error.response.data.result.status == 601) {
          return (this.currentPopUp = popupEnum.ExchangeFail601);
        }
        if (error.response.data.result.status == 602) {
          return (this.currentPopUp = popupEnum.ExchangeFail602);
        }
        if (error.response.data.result.status == 603) {
          return (this.currentPopUp = popupEnum.ExchangeFail603);
        }
        if (error.response.data.result.status == 604) {
          return (this.currentPopUp = popupEnum.ExchangeFail604);
        }

        this.$toast.error(error.response.data.result.error, {
          icon: true,
          closeButton: "button",
          type: "error",
        });
      }
    },

    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    showPopup(value) {
      if (value == popupEnum.Login) {
        localStorage.redirectUrl = `/club/voucher-detail/${this.$route.params.Slug}`;
        return this.$router.push({ path: "/dang-nhap" });
      }
      if (value == popupEnum.ExchangePoints) {
        if (this.serverParams.rankId) {
          this.currentPopUp = popupEnum.ExchangePoints;
          return (this.modalClass = "active ");
        } else {
          this.currentPopUp = popupEnum.PendingUser;
          return (this.modalClass = "active ");
        }
      }
      this.modalClass = "active ";
      this.currentPopUp = value;
    },
    closePopup() {
      this.modalClass = "";
      this.currentPopUp = null;
    },

    reRegiterLoyaltyUser() {
      this.currentPopUp = popupEnum.Login;
    },

    regiterLoyaltyUser() {
      return useJwt.get(`otp/${this.loginLoyalty.Phone}`).then((response) => {
        this.currentPopUp = popupEnum.Otp;
      });
    },

    async loginLoyaltyUser() {
      const params = {
        username: this.loginLoyalty.Phone,
        password: this.loginLoyalty.Otp,
      };
      try {
        const res = await useJwt.post("otp-login", params);
        this.tokenLoyalty = res.data.result.token;
        this.getCustomerInfo();
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },
    async getCustomerInfo() {
      try {
        const res = await useJwt.get("club/clients", {
          headers: {
            apiKey: `${this.tokenLoyalty}`,
          },
        });
        if (res.data.code == 200) {
          this.customerInfo = res.data.result.data;
          this.serverParams.rankId = res.data.result.data.rankId;
          this.isLogin = true;
          this.closePopup();
        }
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },

    onActiveTabPopup(value) {
      console.log(value);
      this.activeTabPopup = value;
    },

    onSwiper(swiper) {
      this.swiper = swiper;
      this.snapGrid = swiper.snapGrid;
    },

    onNextVoucher() {
      this.swiper.slideNext();
    },

    onPrevVocuher() {
      this.swiper.slidePrev();
    },

    onSlideChange(swiper) {
      this.currentSnap = swiper.snapIndex;
      this.isEndSlide = swiper.isEnd;
      this.activeIndexSide = swiper.activeIndex;
    },
    onSnap(index) {
      this.swiper.slideTo(index);
    },

    async getVipVoucherUser() {
      try {
        const res = await useJwt.post(
          `club/get-vip-voucher?page=${this.vipVoucherParams.currentPage}&pageSize=${this.vipVoucherParams.pageSize}`,
          {},
          {
            headers: {
              apiKey: `${this.tokenLoyalty}`,
            },
          }
        );
        if (res.data.code == 200) {
          this.normalVoucher = res.data.result.data.content;
        }
      } catch (error) {
        this.$toast.error(
          "Có lỗi trong khi lấy dữ liệu Itel club, Quý khách vui lòng thử lại!",
          { icon: true, closeButton: "button" }
        );
      }
    },
  },

  mounted() {
    this.getVoucherDetail();
    // this.getFillteredVouchers(1);
    this.getBannerLoyalty();

    if (localStorage.token) {
      this.tokenLoyalty = localStorage.token;
      console.log("token ", this.tokenLoyalty);
      this.getCustomerInfo();
    }
  },

  components: {
    Swiper,
    SwiperSlide,
    BCard,
    BCol,
    BRow,
    BContainer,
    BPagination,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BModal,
    BButton,
    SurveyCES,
  },
};
</script>

<style scopped>
.fs-container {
  overflow: hidden;
}
.banner__club .swiper-pagination {
  width: 100%;
  left: 0;
  bottom: 4%;
}

.snap {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgb(153, 148, 148);
  cursor: pointer;
}
.snap-active {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #ff2424;
}

.nav__inr,
.member__inr,
.discover__inr,
.voucher__inr,
.promotion__inr,
.endow__inr {
  max-width: 117rem;
}

.nav__inr {
  padding: 3.4rem 0 0 0;
}
.nav__inr .nav-news {
  margin: 0;
}

.section__member {
  padding: 2rem 0 0 0;
}
.member__inr {
  display: flex;
  align-items: center;
  padding: 3rem 0;
  box-shadow: 0 10px 30px rgba(209, 213, 223, 0.5);
  margin-bottom: 1.4rem;
}
.member__item {
  margin-left: 16%;
}
.member__item p {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
}
.member__item p.color__red {
  color: #ed1d24;
}
.has__start {
  padding-left: 4rem;
}
.start__red {
  background: url(../assets/images/club/start__red.png) no-repeat;
  background-size: 3.5rem auto;
}
.start__silver {
  background: url(../assets/images/club/start__silver.png) no-repeat;
  background-size: 3.5rem auto;
}
.start__yellow {
  background: url(../assets/images/club/start__yellow.png) no-repeat;
  background-size: 3.5rem auto;
}
.start__diamond {
  background: url(../assets/images/club/start__diamond.png) no-repeat;
  background-size: 3.5rem auto;
}

.voucher__box {
  position: relative;
}
.voucher__box .swiper-pagination {
  left: 30rem;
  bottom: 2.5rem;
}

.section__discover {
  padding: 2rem 0 0 0;
}
.discover__inr {
  padding: 0 15rem;
  display: flex;
  overflow: hidden;
  box-shadow: 0 10px 30px rgba(209, 213, 223, 0.5);
  margin-bottom: 2rem;
  align-items: center;
}
.discover__pic {
  flex: 0 0 18%;
  max-width: 18%;
  margin-right: 7%;
}
.discover__pic img {
  margin: -1.5rem 0;
}
.discover__txt {
  flex: 0 0 82%;
  max-width: 82%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.discover__detail {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
}
.discover__txt .login__but button {
  width: 15rem;
  padding: 0;
  -webkit-border-radius: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  height: 5rem;
  text-transform: initial;
}

.section__voucher {
  padding-bottom: 5rem;
}

.endow__block {
  background-color: #ed1d24;
  padding: 3rem 10rem;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  position: relative;
  margin-top: 25rem;
  top: -20rem;
  margin-bottom: -20rem;
}

.endow__title {
  font-size: 3.6rem;
  color: #fff;
  font-weight: 700;
  margin-bottom: 2rem;
}
.endow__title h1,
.endow__title h2,
.endow__title h3,
.endow__title h4,
.endow__title h5,
.endow__title h6 {
  text-transform: uppercase;
}
.endow__title p {
  font-size: 1.6rem;
  color: #fff;
  font-weight: 400;
}

.endow__block .swiper-slide {
  background-color: transparent;
}
.endor__item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 2.8rem 1rem 2.8rem 3rem;
  margin-bottom: 2rem;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #fff;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
}
.endor__item:last-child {
  margin-bottom: 0;
}
.endor__item::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 86%;
  left: 27%;
  top: 7%;
  border-left: 1px dashed #d9d9d7;
}
.endor__bg {
  flex: 0 0 18%;
  max-width: 18%;
  margin-right: 8%;
  border-radius: 1rem;
}
.endor__bg::before {
  padding-top: 100%;
}
.endor__txt {
  flex: 0 0 54%;
  max-width: 54%;
}
.endor__txt h3 {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
  margin-bottom: 1.2rem;
}
.endor__date {
  padding-left: 2.4rem;
  background: url(../assets/images/club/icon_date.png) no-repeat;
  color: #999999;
  font-weight: 500;
  font-size: 1.6rem;
  background-size: 1.6rem auto;
}
.endor__value {
  flex: 0 0 16%;
  max-width: 16%;
  font-size: 2rem;
  font-weight: 400;
  color: #fff;
  padding: 2rem 1rem;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  background-color: #6adcd9;
  text-align: center;
}

.endow__box {
  position: relative;
}

.section__promotion {
  background-color: #f5f5f5;
}

.promotion__top {
  display: flex;
  flex-wrap: wrap;
  padding: 5rem 0 4.4rem 0;
  justify-content: space-between;
}

.promotion__title {
  font-size: 3.6rem;
  color: #393e46;
  font-weight: 700;
}
.promotion__title h1,
.promotion__title h2,
.promotion__title h3,
.promotion__title h4,
.promotion__title h5,
.promotion__title h6 {
  text-transform: uppercase;
}

.promotion__title p {
  font-size: 1.6rem;
  color: #6f7885;
  font-weight: 400;
}

.promotion__sort {
  display: flex;
}

.sort__col {
  width: 18rem;
  margin-left: 3.5%;
}

.promotion__list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 3rem;
}

.promotion__item {
  position: relative;
  flex: 0 0 32%;
  max-width: 32%;
  margin: 0 2% 2% 0;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  overflow: hidden;
  background-color: #fff;
  padding-bottom: 3rem;
  box-shadow: 0 10px 30px rgba(209, 213, 223, 0.5);
}
.promotion__item:nth-child(3n) {
  margin: 0 0 2% 0;
}
.promotion__bg {
  margin-bottom: 1 rem;
}
.promotion__bg::before {
  padding-top: 100%;
}
.promotion__money {
  font-size: 2rem;
  color: #fff;
  font-weight: 700;
  position: absolute;
  top: 1.7rem;
  right: 1rem;
  background-color: #ec1d22;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
  padding: 1rem;
  z-index: 1;
}

.promotion__txt,
.core__block {
  padding: 0 3rem;
}
.promotion__txt {
  margin-bottom: 2.2rem;
}
.promotion__txt h3 {
  font-size: 2rem;
  color: #141821;
  font-weight: 700;
  min-height: 6rem;
}
.core__block {
  display: flex;
  flex-wrap: wrap;
}
.core__bg {
  flex: 0 0 27%;
  max-width: 27%;
  margin-right: 8%;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
}
.core__bg::before {
  padding-top: 100%;
}
.core__value,
.core__date {
  font-size: 1.6rem;
  font-weight: 500;
}
.core__value {
  background: url(../assets/images/club/icon_core.png) no-repeat;
  color: #ec1d22;
  margin-bottom: 1rem;
  padding-left: 2.4rem;
  background-size: 1.6rem auto;
  background-position: left center;
}
.core__date {
  padding-left: 2.4rem;
  background: url(../assets/images/club/icon_date.png) no-repeat;
  color: #999999;
  background-size: 1.6rem auto;
  background-position: left center;
}

.news__promotion {
  background-color: #f5f5f5;
  padding: 0;
}

.category__list {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4.6rem 0;
}
.category__list a {
  padding: 1.5rem 3.5rem;
  font-size: 1.6rem;
  color: #393e46;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0);
  -webkit-border-radius: 3rem;
  border-radius: 3rem;
  margin: 0 3.2rem;
  cursor: pointer;
}
.category__list a.active {
  background-color: rgba(255, 255, 255, 1);
  color: #393e46;
}

.voucher__detail {
  padding: 4rem 0 0 0;
  margin-bottom: -9.4rem;
  z-index: 2;
}

.promotion__detail {
  background-color: #f5f5f5;
  padding: 11rem 0 6.6rem 0;
}
.promotion__detail__inr {
  max-width: 117rem;
}
.promotion__detail__box,
.promotion__slider {
  display: block;
  width: 100%;
}
:host ::v-deep .promotion__slider .swiper-container {
  padding-bottom: 3%;
}

.promotion__detail__inr .promotion__item .core__block .fs-buts.fs-sp {
  display: none;
}
.promotion__detail__box {
  display: flex;
  flex-wrap: wrap;
  padding: 3.8rem 3rem;
  background-color: #fff;
  box-shadow: 0 10px 30px rgba(209, 213, 223, 0.5);
  font-size: 1.6rem;
  color: #333333;
  font-weight: 400;
  line-height: 1.6;
}
.promotion__detail__box .promotion__item {
  box-shadow: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  flex: 0 0 67%;
  max-width: 67%;
  margin: 0 3% 0 0;
}
.promotion__detail__box .core__block {
  padding: 0 2.4%;
  position: relative;
  z-index: 1;
  margin-top: -4.5rem;
  align-items: flex-end;
}
.promotion__detail__box .core__bg {
  flex: 0 0 18%;
  max-width: 18%;
  margin-right: 2.4%;
  border: 1rem solid #fff;
}
.promotion__detail__box .core__txt {
  flex: 0 0 79.6%;
  max-width: 79.6%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 3rem;
}
.core__txt h3 {
  width: 100%;
  font-size: 2.4rem;
  color: #141821;
  font-weight: 700;
  margin-bottom: 1.4rem;
}
.promotion__detail__box .core__value,
.promotion__detail__box .core__date {
  flex: 0 0 33%;
  max-width: 33%;
  margin: 0;
}

.promotion__detail__box h4 {
  position: relative;
  font-weight: 700;
  font-size: 1.6rem;
  padding-bottom: 1.6rem;
}
.tab__condition h4 {
  margin-bottom: 2rem;
  border-bottom: 1px solid #e5e5e5;
}
.tab__condition h4::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: 2px;
  background-color: #ed1f24;
}

.tab__condition {
  flex: 0 0 30%;
  max-width: 30%;
}
.condition__list {
  padding: 1rem 2rem 2rem 2rem;
  max-height: 37rem;
  overflow: hidden;
  overflow-y: auto;
}
.condition__list ul {
  padding: 0.7rem 0;
  list-style: none;
}
.condition__list li {
  padding-left: 4rem;
  font-size: 1.4rem;
  margin-bottom: 3rem;
  line-height: 1.6;
  background: url(../assets/images/club/check.png) no-repeat;
  background-size: 2.4rem auto;
  background-position: left px;
}

.tab__info {
  flex: 0 0 67%;
  max-width: 67%;
  padding: 2.5rem 0;
  font-size: 1.6rem;
  line-height: 1.6;
}
.tab__condition .fs-buts {
  padding-top: 1rem;
}
.tab__condition .fs-but {
  font-size: 1.6rem;
  width: 100%;
  padding: 1.2rem 0;
  -webkit-border-radius: 1rem;
  border-radius: 1rem;
}

.promotion__box {
  position: relative;
}
.promotion__slider .promotion__item {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
  display: block;
  margin: 0 2% 0 0;
}
.promotion__slider .promotion__money {
  font-size: 2rem;
  font-weight: 700;
}

.modal-inner {
  margin: auto;
  border: none;
  background: none;
  position: absolute;
  top: 10%;
  right: -10%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  width: 960px;
}

@media screen and (max-width: 1100px) {
  .member__inr,
  .discover__inr,
  .voucher__inr,
  .endow__inr {
    width: 94%;
  }
  .promotion__inr {
    width: 100%;
  }

  .nav__inr {
    width: 100%;
    padding: 7vw 0 0 0;
  }

  .section__discover {
    padding: 4.26666vw 0 0 0;
  }
  .discover__inr {
    padding: 1.7777vw 3.5vw 0 3.5vw;
    margin-bottom: 8vw;
  }
  .discover__pic {
    flex: 0 0 32%;
    max-width: 32%;
    margin-right: 4%;
  }
  .discover__pic img {
    margin: 0;
  }
  .discover__txt {
    flex: 0 0 64%;
    max-width: 64%;
    flex-wrap: wrap;
  }
  .discover__detail {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: center;
    font-size: 3.5555vw;
    margin-bottom: 2.6666vw;
  }
  .discover__txt .fs-buts {
    flex: 0 0 100%;
    text-align: center;
    max-width: 100%;
  }
  .discover__txt .login__but button {
    font-size: 2.8444vw;
    height: auto;
    width: auto;
    padding: 2.2vw 5vw;
  }

  .section__member {
    padding: 5.3333vw 0 0 0;
  }
  .member__inr {
    flex-wrap: wrap;
    padding: 3.2vw 8vw;
    justify-content: space-between;
    margin-bottom: 4vw;
  }
  .member__item {
    margin: 0;
  }
  .member__item:nth-child(1) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 3.2vw;
  }
  .member__item p {
    font-size: 2.84444vw;
  }
  .member__item p.member_name {
    font-weight: 400;
  }
  .has__start {
    padding-left: 7vw;
  }
  .start__red,
  .start__silver,
  .start__yellow,
  .start__diamond {
    background-size: 6vw auto;
  }

  .endow__box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .endow__block {
    margin: 0;
    top: auto;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 4.62222vw 3%;
  }
  .endor__item {
    padding: 6vw 3% 6vw 6%;
    margin-bottom: 2.222vw;
  }
  .endor__txt h3 {
    font-size: 3.911vw;
    margin-bottom: 2.666vw;
  }
  .endor__date {
    padding-left: 4.8vw;
    background-size: 3.37777vw auto;
    font-size: 3.2vw;
  }
  .endor__value {
    padding: 3.5vw 3.1vw;
    font-size: 3.911vw;
  }

  .endow__title {
    font-size: 7.111vw;
    margin-bottom: 8vw;
  }
  .endow__title p {
    font-size: 2.8444vw;
  }

  .promotion__top {
    padding: 5.3333vw 3%;
    flex-wrap: wrap;
  }
  .promotion__title {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .promotion__sort {
    flex: 0 0 100%;
    max-width: 100%;
    justify-content: space-between;
  }
  .sort__col {
    width: 31%;
    margin: 0;
  }

  .promotion__title {
    font-size: 7.111vw;
    margin-bottom: 0;
  }
  .promotion__title p {
    font-size: 2.8444vw;
  }

  :host ::v-deep .promotion__sort .fs-select-header::after {
    background-size: 2.666vw auto;
  }
  :host ::v-deep .promotion__sort .fs-select-header::after {
    right: 2vw;
  }
  :host ::v-deep .promotion__sort .fs-select-header span {
    font-size: 3.375vw;
  }

  .promotion__box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .promotion__list {
    padding: 0 3%;
  }
  :host ::v-deep .promotion__slider .swiper-container {
    padding-bottom: 4.5%;
  }
  .promotion__item {
    padding-bottom: 3.37777vw;
    -webkit-box-shadow: 0 5px 8px rgba(209, 213, 223, 0.5);
    box-shadow: 0 5px 8px rgba(209, 213, 223, 0.5);
  }
  .promotion__item,
  .promotion__item:nth-child(3n) {
    flex: 0 0 48.5%;
    max-width: 48.5%;
    margin: 0 3% 3% 0;
  }
  .promotion__item:nth-child(2n) {
    margin: 0 0 3% 0;
  }
  .promotion__bg {
    margin-bottom: 3.37777vw;
  }
  .promotion__txt,
  .core__block {
    padding: 0 5%;
    margin: 0;
  }
  .promotion__txt h3 {
    font-size: 3.2vw;
    margin-bottom: 3vw;
    min-height: 9.5vw;
  }
  .core__txt {
    padding-top: 1vw;
  }
  .core__value,
  .core__date {
    font-size: 2.84444vw;
    background-size: 3vw auto;
    padding-left: 5vw;
  }
  .promotion__money {
    right: 1.3333vw;
    top: 1.3333vw;
    font-size: 4.26666vw;
    padding: 1.6666vw 3vw;
  }

  .promotion__detail__box .core__value,
  .promotion__detail__box .core__date {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .category__list {
    width: 94%;
    margin: 0 auto;
    padding: 5.333vw 0;
  }
  .category__list a {
    font-size: 2.84444vw;
    padding: 3vw 5.4vw;
    margin: 0 2vw;
  }

  .promotion__detail__inr {
    width: 94%;
  }

  .promotion__detail {
    background-color: #fff;
    padding: 11.5vw 0;
  }
  .promotion__detail__box {
    padding: 2.6666vw;
  }
  .promotion__detail__box .promotion__item {
    flex: 0 0 100%;
    max-width: 100%;
    margin: 0 0 7vw 0;
  }
  .tab__condition,
  .tab__info {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .promotion__detail__box .core__block {
    margin-top: -6vw;
    padding: 0 0 0 4%;
  }
  .promotion__detail__box .core__bg {
    flex: 0 0 24%;
    max-width: 24%;
    border: 0.88vw solid #fff;
    margin-right: 4%;
  }
  .promotion__detail__box .core__txt {
    flex: 0 0 100%;
    max-width: 100%;
    top: auto;
    margin-top: 4.88vw;
  }
  .promotion__detail__box .core__txt h3 {
    font-size: 4.8vw;
    margin-bottom: 1.4vw;
  }
  .promotion__detail__box .core__value,
  .promotion__detail__box .core__date {
    font-size: 4vw;
  }

  .tab__box {
    font-size: 3.375vw;
    line-height: 1.6;
    display: none;
  }
  .tab__box.active {
    display: block;
  }
  .tab__box p {
    margin-bottom: 3vw;
  }

  .tab__box h4 {
    display: none;
  }

  .condition__list {
    padding: 3.2vw 1.777vw;
    max-height: initial;
  }
  :host ::v-deep .condition__list ul {
    padding: 0;
  }
  :host ::v-deep .condition__list li {
    background-size: 5.3333vw auto;
    background-position: left 1vw;
    font-size: 3.375vw;
    margin-bottom: 1.2vw;
    padding-left: 9.06vw;
    padding: 1vw 0 1vw 9.06vw;
  }

  .tab__header {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    flex: 0 0 100%;
  }
  .tab__item {
    position: relative;
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 5% 3vw 5%;
    font-size: 3.375vw;
    font-weight: 700;
    text-transform: uppercase;
    color: #adadad;
  }
  .tab__item.active {
    color: #393e46;
  }
  .tab__item::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #ed1f24;
    opacity: 0;
  }
  .tab__item.active::after {
    opacity: 1;
  }

  .promotion__detail__box .core__block {
    justify-content: space-between;
  }
  .promotion__detail__box .fs-buts {
    flex: 0 0 60%;
    max-width: 60%;
  }
  .promotion__detail__box .fs-but {
    padding: 0;
    font-size: 3.2vw;
    width: 100%;
    height: 10vw;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  .modal-inner {
    top: 20%;
    right: -10%;
    width: 95%;
  }
}
/* mobile */
@media screen and (max-width: 520px) {
  :host ::v-deep .endow__box .swiper-pagination-bullet,
  :host ::v-deep .promotion__box .swiper-pagination-bullet {
    width: 1.6rem;
    height: 1.6rem;
    margin: 0 5px;
  }
  :host ::v-deep .endow__box .swiper-pagination-bullet::before,
  :host ::v-deep .promotion__box .swiper-pagination-bullet::before {
    width: 1rem;
    height: 1rem;
  }
  :host ::v-deep .endow__box .swiper-pagination-bullet-active,
  :host ::v-deep .promotion__box .swiper-pagination-bullet-active {
    width: 3.2rem;
  }
  :host ::v-deep .endow__box .swiper-pagination-bullet-active::before,
  :host ::v-deep .promotion__box .swiper-pagination-bullet-active::before {
    width: 2.6rem;
  }

  .endow__box .swiper-button-prev,
  .endow__box .swiper-button-next {
    top: 6vw;
  }
  .promotion__box .swiper-button-prev,
  .promotion__box .swiper-button-next {
    top: 2vw;
  }
  .promotion__detail__inr .promotion__item .core__block .fs-buts.fs-sp {
    display: block;
  }
  .promotion__slider .promotion__money {
    font-size: 2rem;
    font-weight: 700;
  }
  .tab_header_box {
    padding: 0;
    width: 100%;
  }
  .tab_item {
    border-bottom: 1px solid #aea5a5;
  }
  .tab_item.active {
    border-bottom: 2px solid #ed1f24;
  }

  .swiper-wrapper {
    width: 100%;
    margin-bottom: 10px;
  }
}
</style>